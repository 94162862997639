// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-get-involved-for-investors-js": () => import("./../../../src/pages/get-involved/for-investors.js" /* webpackChunkName: "component---src-pages-get-involved-for-investors-js" */),
  "component---src-pages-get-involved-for-students-js": () => import("./../../../src/pages/get-involved/for-students.js" /* webpackChunkName: "component---src-pages-get-involved-for-students-js" */),
  "component---src-pages-get-involved-for-ventures-js": () => import("./../../../src/pages/get-involved/for-ventures.js" /* webpackChunkName: "component---src-pages-get-involved-for-ventures-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-people-advisors-js": () => import("./../../../src/pages/people/advisors.js" /* webpackChunkName: "component---src-pages-people-advisors-js" */),
  "component---src-pages-people-alumni-js": () => import("./../../../src/pages/people/alumni.js" /* webpackChunkName: "component---src-pages-people-alumni-js" */),
  "component---src-pages-people-our-team-js": () => import("./../../../src/pages/people/our-team.js" /* webpackChunkName: "component---src-pages-people-our-team-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

